import defaultPreset from '../presets-thumbnails/default.png';
import labelTopPreset from '../presets-thumbnails/label-top.png';
import labelInlinePreset from '../presets-thumbnails/label-inline.png';
import labelBottomPreset from '../presets-thumbnails/label-bottom.png';
import digitsCounterPreset from '../presets-thumbnails/digits.png';
import { desktopPresets } from './presetsIds';

export const counterDesignPresetsDesktop = [
  {
    id: desktopPresets[0].id,
    src: defaultPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: desktopPresets[4].id,
    src: digitsCounterPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: desktopPresets[2].id,
    src: labelInlinePreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: desktopPresets[1].id,
    src: labelBottomPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
  {
    id: desktopPresets[3].id,
    src: labelTopPreset,
    layout: {
      width: '100%',
      height: '100%',
      margin: '0 24px 20px 0',
    },
  },
];
