import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { categoriesData, elementsData } from './showHideData';
import { TFunction } from '@wix/yoshi-flow-editor';
import { END_MESSAGE_STATE } from '../constants';

export const openShowHidePanel = (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  currentTab: any,
): Promise<void> => {
  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0].componentRef;
  };

  const addSeparatorInTimerUnits = async (role, roleWidgetRef) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });

    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const unitsDataBeforeShowSeparator = await getUnitsDataBeforeAddSeparator(
      role,
      widgetRefHost,
      roleWidgetRef,
    );
    const notCollapsedItems = unitsDataBeforeShowSeparator.filter((data) => {
      return !data.isCollapsed;
    });
    for (const data of notCollapsedItems) {
      const index = notCollapsedItems.indexOf(data);
      if (index < notCollapsedItems.length - 1) {
        if (
          !data.isCollapsed &&
          data.isDigit &&
          !notCollapsedItems[index + 1].isCollapsed &&
          notCollapsedItems[index + 1].isDigit
        ) {
          const tempIndex = unitsDataBeforeShowSeparator.findIndex(
            (roleData) => {
              return roleData.role === data.role;
            },
          );
          const ref = collapsedRefComponents.find(
            (comp) =>
              comp.role === unitsDataBeforeShowSeparator[tempIndex + 1].role,
          );
          if (ref) {
            showComp(ref.componentRef);
          }
        }
      }
    }
  };
  const getUnitsDataBeforeAddSeparator = async (
    role,
    widgetRefHost,
    roleWidgetRef,
  ) => {
    const unitsRoles = [
      'days',
      'seperator1',
      'hours',
      'seperator2',
      'minutes',
      'seperator3',
      'seconds',
    ];

    const separatorsRole = ['seperator1', 'seperator2', 'seperator3'];
    if (unitsRoles.includes(role)) {
      const unitsDataBeforeShowSeparator: any = [];
      for (const unitRole of unitsRoles) {
        const isCollapsed = await isCollapsedComponentByRole(
          unitRole,
          roleWidgetRef,
        );

        unitsDataBeforeShowSeparator.push({
          role: unitRole,
          isCollapsed,
          isDigit: !separatorsRole.includes(unitRole),
        });
      }

      return unitsDataBeforeShowSeparator;
    }
  };

  const asyncEvery = async (arr, predicate) => {
    for (const e of arr) {
      if (!(await predicate(e))) {
        return false;
      }
    }
    return true;
  };
  const isCollapsedComponentByRole = async (role: string, roleWidgetRef) => {
    const widgetRefAncestors = await editorSDK.components.getAncestors(
      'token',
      {
        componentRef: roleWidgetRef,
      },
    );

    let elementRef;
    await asyncEvery(widgetRefAncestors, async (currentRef) => {
      const element = await editorSDK.document.components.findAllByRole(
        'token',
        {
          controllerRef: currentRef,
          role,
        },
      );
      if (element.length) {
        elementRef = element[0];
        return false;
      }
      return true;
    });

    const isReferredCompCollapsed =
      await editorSDK.document.components.refComponents.isRefComponentCollapsed(
        'token',
        { componentRef: elementRef },
      );

    return isReferredCompCollapsed;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef, role) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async ({ role }, roleWidgetRef) => {
    const componentRef = await getCollapsedRefComponentByRole(role);
    const element = await showComp(componentRef);

    const timerUnits = ['days', 'hours', 'minutes', 'seconds'];
    const widgetElements = ['text1', 'button1', 'text2', 'button2'];
    if (timerUnits.includes(role)) {
      updateUnitsProps(role, true);
      await addSeparatorInTimerUnits(role, roleWidgetRef);
    }
    if (widgetElements.includes(role)) {
      updateUnitsProps(role, true);
    }
    return element;
  };

  const removeCompHandler = async (compRef, { role }) => {
    const compToHide = await getCompToHide(compRef);
    const timerUnits = [
      'days',
      'hours',
      'minutes',
      'seconds',
      'text1',
      'button1',
      'text2',
      'button2',
    ];
    if (timerUnits.includes(role)) {
      updateUnitsProps(role, false);
    }
    return hideComp(compToHide, role);
  };

  const updateUnitsProps = async (role, isRoleDisplayed) => {
    let newProps = {};
    switch (role) {
      case 'text1':
        newProps = {
          countdownTitleShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'button1':
        newProps = {
          countdownButtonShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'text2':
        newProps = {
          endMessageTitleShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'button2':
        newProps = {
          endMessageButtonShowInElementsPanel_countdown: isRoleDisplayed,
        };
        break;
      case 'days':
        newProps = { daysShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'hours':
        newProps = { hoursShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'minutes':
        newProps = { minutesShowInElementsPanel_countdown: isRoleDisplayed };
        break;
      case 'seconds':
        newProps = { secondsShowInElementsPanel_countdown: isRoleDisplayed };
        break;
    }

    await editorSDK.application.appStudioWidgets.props.set('token', {
      widgetRef,
      newProps,
    });
  };

  const filteredCategoriesData =
    currentTab === 'Tab 1'
      ? categoriesData(t).filter((data) => {
          return data?.state !== END_MESSAGE_STATE;
        })
      : categoriesData(t).filter((data) => data?.state === END_MESSAGE_STATE);

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData: filteredCategoriesData,
    elementsData: elementsData(t),
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
  });
};
